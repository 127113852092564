
import { Options, Vue } from 'vue-class-component'
import { coreClient } from '@/libs'
import Spinner from '@/components/Spinner.vue'
import MessagesComponent from '@/components/Messages.vue'

// #region Interfaces
interface ISystemStatus {
  status: string
  version: string
  gitVersion: string
  gitHash: string
  env: string
  app: string
}

interface ISystemConfig {
  appRoles: IAppRole[]
  permissions: string[]

  connectionStrings: IConnectionStrings
  weblink: IWeblinkConfig
  azureAd: IAzureAdConfig
  coreServices: ICoreServicesConfig
  visma: IVismaConfig
  activeDirectory: IActiveDirectoryConfig
  smtp: ISmtpConfig

  serviceBus: Map<string, IServiceBusTopicConfig>
}

interface IAppRole {
  guid: string
  name: string
  permissions: string[]
}

interface IConnectionStrings {
  coreDb: string
  callDetailRecordDb: string
  ordRegDb: string
  poolIntranetDb: string
  vismaGeneralDb: string
}

interface IWeblinkConfig {
  serverURL: string
  username: string
}

interface IAzureAdConfig {
  instance: string
  tenantId: string
  clientId: string
  audience: string
  appObjectId: string
  cacheTimeout: number
  authority: string
}

interface IServiceBusTopicConfig {
  topicName: string
  subscriptionName: string
}

interface ICoreServicesConfig {
  logoStorageBackend: string
  emailSenderBackend: string
  activeDirectoryBackend: string
  emailRecipients: Map<string, string>
  visma: ICoreServicesVismaConfig
}

interface ICoreServicesVismaConfig {
  endpointUrl: string
  applicationId: string
}

interface IVismaConfig {
  generalConnectionString: string
}

interface IActiveDirectoryConfig {
  server: string
  username: string
}

interface ISmtpConfig {
  sender: string
  host: string
  port: number
  username: string
  enableSSL: boolean
}

type SystemConnectivityTestResultsDTO = Map<string, ISystemConnectivityStatusDTO>

interface ISystemConnectivityStatusDTO {
  isConnected: boolean
  responseMessage: string
}
// #endregion

@Options({
  name: 'StatusView',
  components: {
    Spinner,
  },
})
export default class StatusView extends Vue {
  loadingSystemStatus = false
  systemStatus: ISystemStatus | null = null

  loadingSystemConfig = false
  systemConfig: ISystemConfig | null = null

  runningSystemConnectivityTest = false
  systemConnectivityTestResults: SystemConnectivityTestResultsDTO | null = null

  mounted(): void {
    this.fetchSystemStatus().then((status) => (this.systemStatus = status))
    this.fetchSystemConfig().then((config) => (this.systemConfig = config))
  }

  update(): void {
    this.systemStatus = null
    this.systemConfig = null

    this.fetchSystemStatus().then((status) => (this.systemStatus = status))
    this.fetchSystemConfig().then((config) => (this.systemConfig = config))
  }

  async fetchSystemStatus(): Promise<ISystemStatus | null> {
    this.loadingSystemStatus = true
    try {
      const { data } = await coreClient.get<ISystemStatus>('/api/status')
      return data
    } catch (err: unknown) {
      ;(this.$refs.errorHandler as MessagesComponent).pushErr(err)
      return null
    } finally {
      this.loadingSystemStatus = false
    }
  }

  async fetchSystemConfig(): Promise<ISystemConfig | null> {
    const msgs = this.$refs.errorHandler as MessagesComponent
    msgs.clear()

    try {
      this.loadingSystemConfig = true
      const { data } = await coreClient.get<ISystemConfig>('/api/systemConfiguration')
      return data
    } catch (err: unknown) {
      msgs.pushErr(err)
      return null
    } finally {
      this.loadingSystemConfig = false
    }
  }

  async runSystemConnectivityTest(): Promise<void> {
    const msgs = this.$refs.systemConnectivityErrorHandler as MessagesComponent
    msgs.clear()

    try {
      this.runningSystemConnectivityTest = true
      this.systemConnectivityTestResults = null
      const { data } = await coreClient.get<SystemConnectivityTestResultsDTO>('/api/testSystemConnectivity')
      const unsortedMap = new Map(Object.entries(data))
      this.systemConnectivityTestResults = new Map([...unsortedMap.entries()].sort())
    } catch (err: unknown) {
      msgs.pushErr(err)
    } finally {
      this.runningSystemConnectivityTest = false
    }
  }
}
